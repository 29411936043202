import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material';
import { Divider, Grid, Stack, Typography } from '@mui/material';

// project imports
import AuthWrapper from 'components/auth/AuthWrapper';
import AuthCardWrapper from 'components/auth/AuthCardWrapper';
import AuthLogin from 'components/auth/Form';
import Logo from 'src/layouts/Logo';
import { useTranslation } from 'react-i18next';
import { GTM } from 'src/utils/gtm';

// assets

// ================================|| AUTH - LOGIN ||================================ //

const Login = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <AuthWrapper>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <AuthCardWrapper>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item sx={{ mb: 1 }}>
                    <Logo />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction={['column-reverse', 'row']} alignItems="center" justifyContent="center">
                      <Grid item>
                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                          <Typography
                            color={
                              theme.palette.mode === 'light' ? theme.palette.neutral[700] : theme.palette.neutral[300]
                            }
                            gutterBottom
                            variant="h5"
                          >
                            {t('auth.welcome_message')}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container direction={['column-reverse', 'row']} alignItems="center" justifyContent="center">
                      <Grid item>
                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                          <Typography
                            style={{
                              color: '#8B0000', // Muted dark red for the text
                              backgroundColor: 'rgb(233, 223, 132)', // Softer yellow shade
                              padding: '8px', // Optional padding for better readability
                              borderRadius: '4px', // Optional border radius for styling
                              fontSize: '14px', // Reduced font size
                              fontWeight: '500', // Makes the font bold
                            }}
                            gutterBottom
                            variant="h5"
                          >
                            {t('auth.temporyBankIdDown')}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <AuthLogin />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item container direction="column" alignItems="center" xs={12}>
                      <Typography
                        component={Link}
                        to="/register"
                        color="primary"
                        variant="subtitle1"
                        sx={{ textDecoration: 'none' }}
                        onClick={() => GTM.createAccountButton()}
                      >
                        {t('auth.create_account')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </AuthCardWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default Login;
