import { Alert, Box, Button, Divider, Stack, TextField, Typography } from '@mui/material';
import { bankIDAuth, bankIDPoll } from 'src/api/auth';
import { useAuthentication } from 'src/hooks/use-auth';
import { useBankId } from 'src/hooks/use-bankID';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BankIDPending } from './Pending';
import { useTranslation } from 'react-i18next';
import { validateSSN } from 'src/utils/validations/ssn';
import { GTM } from 'src/utils/gtm';
import { useEffect, useState } from 'react';
import { openBankId } from 'src/utils/bankID';
import QRCode, { QRCodeSVG } from 'qrcode.react';
import CryptoJS from 'crypto-js';
import { BankIDAuth } from 'interfaces/auth';

type Inputs = {
  ssn: string;
};

function BankIDForm() {
  const [searchParams] = useSearchParams();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>();
  const { populate } = useAuthentication();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [pollError, setPollError] = useState<null | string>(null);
  const [qrData, setQrData] = useState<null | string>(null);
  const [secondsSinceOrderCreation, setSecondsSinceOrderCreation] = useState<number>(0);
  const [qrAuthCode, setQRAuthCode] = useState<null | string>(null);
  const [bankIDRes, setbankIDRes] = useState<null | BankIDAuth>(null);
  const refreshTime = 30;

  const [state, dispatchBankID] = useBankId({
    pollLimit: 30,
    pollWait: 1000,
    onResolved: auth => {
      populate(auth);
      navigate('/');
    },
    onPollRejection: err => {
      if (err.status && err.hintCode) {
        setPollError(t(`auth.bankID.status.${err.status}.${err.hintCode}`));
      } else {
        setPollError(t(`auth.bankID.error.generalPoll`));
      }
    },
    onAuthRejection: err => {
      if (err.response?.status) {
        setPollError(t(`auth.bankID.error.${err.response.status}.${err.response.data.code}`));
      } else {
        setPollError(t(`auth.bankID.error.general`));
      }
    },
    effects: {
      auth(ssn, eDispatch) {
        bankIDAuth(ssn)
          .then(res => {
            eDispatch({ type: 'resolve_auth', reference: res.orderRef });
            if (!ssn) {
              openBankId({
                autoStartToken: res.autoStartToken,
              });
            } else {
              setbankIDRes(res);
              setSecondsSinceOrderCreation(0);
            }
          })
          .catch(error => {
            eDispatch({ type: 'reject_auth', error });
            GTM.bankIDSubmit({ action: 'error', error: JSON.stringify(error) });
          });
      },
      poll(reference, eDispatch) {
        bankIDPoll(reference)
          .then(res => {
            if (res.authorizationResponse) {
              eDispatch({ type: 'resolve_poll', progress: 'success', auth: res });
              GTM.bankIDSubmit({ action: 'success' });
            } else if (res.collectBankIdStatus.status === 'pending') {
              setPollError(null);
              eDispatch({ type: 'resolve_poll', progress: 'pending', status: res.collectBankIdStatus });
            } else {
              eDispatch({ type: 'reject_poll', error: res.collectBankIdStatus });
              GTM.bankIDSubmit({ action: 'error', error: res.collectBankIdStatus.hintCode });
            }
          })
          .catch(error => {
            eDispatch({ type: 'reject_poll', error });
            GTM.bankIDSubmit({ action: 'error', error: JSON.stringify(error) });
          });
      },
    },
  });

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (secondsSinceOrderCreation < refreshTime && bankIDRes) {
      interval = setInterval(() => {
        setSecondsSinceOrderCreation(prevSeconds => prevSeconds + 1);
      }, 1000);
    } else if (secondsSinceOrderCreation >= refreshTime) {
      initiateBankID();
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [secondsSinceOrderCreation, bankIDRes]);

  useEffect(() => {
    if (secondsSinceOrderCreation <= refreshTime && bankIDRes) {
      generateQRCode(bankIDRes);
    }
  }, [secondsSinceOrderCreation, bankIDRes]);

  useEffect(() => {
    //initiateBankID();
    if (searchParams.get('reference')) {
      const ref = searchParams.get('reference') as string;
      dispatchBankID({ type: 'resume_auth', reference: ref });
    }
  }, [searchParams]);

  const onSubmit: SubmitHandler<Inputs> = data => {
    dispatchBankID({ type: 'auth', ssn: data.ssn });
    GTM.bankIDOtherDevice();
  };

  const initiateBankID = () => {
    setQrData('Please wait we are generating the code.');
    dispatchBankID({ type: 'auth', ssn: '0' });
    GTM.bankIDOtherDevice();
  };
  const generateQRCode = (res: BankIDAuth) => {
    const hmac = CryptoJS.HmacSHA256(secondsSinceOrderCreation.toString(), res.qrStartSecret);
    const qrAuthCode = hmac.toString(CryptoJS.enc.Hex);
    setQRAuthCode(qrAuthCode);
    const qrData = `bankid.${res.qrStartToken}.${secondsSinceOrderCreation}.${qrAuthCode}`;
    setQrData(qrData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <>
        <Stack display="flex" alignItems="center" justifyContent="center" spacing={1} mb={2}>
          <Button
            type="button"
            onClick={() => {
              dispatchBankID({ type: 'auth' });
              GTM.bankIDSameDevice();
            }}
          >
            {t('auth.bankID.open_same_device')}
          </Button>
          <Divider
            orientation="horizontal"
            sx={{
              color: theme =>
                theme.palette.mode === 'light' ? theme.palette.neutral['400'] : theme.palette.neutral['300'],
              textTransform: 'uppercase',
              width: '100%',
            }}
          >
            {t('auth.or')}
          </Divider>
          {/* <Typography
              variant="body1"
              color={theme => (theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.grey[200])}
              fontSize="16px"
              textAlign="center"
            >
              {t('auth.bankID.fill_in_ssn')}
            </Typography> */}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          useFlexGap
          flexWrap="wrap"
          spacing={2}
          mb={2}
        >
          <Stack position="relative">
            <QRCodeSVG value={qrData ?? ''} />
            {!qrData && (
              <Button
                variant="contained"
                color="primary"
                onClick={initiateBankID}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 1,
                  padding: '28px 45px',
                  opacity: 0.97,
                  background: '#5e636e',
                  color: '#6cb2c4',
                }}
              >
                Show QR Code
              </Button>
            )}
          </Stack>

          <Stack>
            <ol style={{ paddingLeft: '10px', margin: 0 }}>
              <Typography
                variant="body1"
                color={theme => (theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.grey[200])}
                fontSize="12px"
                marginBottom="10px"
                textAlign="left"
              >
                <li>{t('auth.bankID.qr_instructions.1')}</li>
              </Typography>
              <Typography
                variant="body1"
                color={theme => (theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.grey[200])}
                fontSize="12px"
                marginBottom="10px"
                textAlign="left"
              >
                <li>{t('auth.bankID.qr_instructions.2')}</li>
              </Typography>
              <Typography
                variant="body1"
                color={theme => (theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.grey[200])}
                fontSize="12px"
                marginBottom="10px"
                textAlign="left"
              >
                <li>{t('auth.bankID.qr_instructions.3')}</li>
              </Typography>
              <Typography
                variant="body1"
                color={theme => (theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.grey[200])}
                fontSize="12px"
                marginBottom="10px"
                textAlign="left"
              >
                <li>{t('auth.bankID.qr_instructions.4')}</li>
              </Typography>
            </ol>
          </Stack>
        </Stack>
        {/* <Stack spacing={1}>
            <TextField
              fullWidth
              label="YYYYMMDDXXXX"
              error={Boolean(errors.ssn)}
              helperText={errors.ssn?.message}
              {...register('ssn', {
                required: { value: true, message: t('auth.validations.ssn.required') },
                maxLength: { value: 12, message: t('auth.validations.ssn.max', { count: 12 }) },
                minLength: { value: 12, message: t('auth.validations.ssn.min', { count: 12 }) },
                validate: value =>
                  validateSSN(value) !== true ? (t('auth.validations.ssn.valid') as string) : undefined,
              })}
              type="tel"
              variant="outlined"
            />
            {pollError ? <Alert severity="error">{pollError}</Alert> : null}
          </Stack>
          <Box sx={{ mt: 2 }}>
            <Button
              disableElevation
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
            >
              {t('auth.bankID.sign')}
            </Button>
          </Box> */}
      </>
    </form>
  );
}

export default BankIDForm;
