import { Access, AuthRequestParams, BankIDAuth, BankIDPoll } from 'src/interfaces/auth';
import { transport } from 'src/utils/transport';

const apiURL = import.meta.env.VITE_API_URL + '/api';

export const AuthEndpoints = {
  authenticate: `${apiURL}/authentication/authenticationForEmail`,
  bankID: `${apiURL}/bankId`,
  customerIp: `${apiURL}/bankId/getCustomerIpAddress`,
  forgotPassword: `${apiURL}/authentication/forgotPasswordUsingEmail`,
  resetPasswordToken: `${apiURL}/authentication/resetPasswordUsingToken`,
  resetPassword: `${apiURL}/authentication/changePassword`,
  register: `${apiURL}/authentication/registerUsingEmail`,
  registerToken: `${apiURL}/authentication/confirmEmailUsingToken`,
};

export const authenticate = (args: AuthRequestParams): Promise<Access> =>
  transport.post(AuthEndpoints.authenticate, args);

export const forgotPassword = (args: { email: string; mailContext: object }) => {
  return transport.post(AuthEndpoints.forgotPassword, args);
};

export const resetPasswordToken = (args: {
  password: string;
  userId: string;
  token: string;
}): Promise<{ code: number }> => {
  return transport.post(AuthEndpoints.resetPasswordToken, args);
};

export const resetPassword = (args: { password: string; oldPassword: string }): Promise<{ code: number }> => {
  return transport.post(AuthEndpoints.resetPassword, args);
};

export const registerCustomer = (args: {
  email: string;
  customerNumber: string;
  password: string;
  type: 'PRV' | 'FTG';
  ssn: string;
  country: string;
  mailContext: object;
}) => {
  return transport.post(AuthEndpoints.register, args);
};

export const registerCustomerToken = (args: { userId: string; token: string }): Promise<{ code: number }> => {
  return transport.post(AuthEndpoints.registerToken, args);
};

export const signOut = () => Promise.resolve({}); //transport.delete(AuthEndpoints.authenticate);

export const getEndUserIp = () => transport.get(AuthEndpoints.customerIp);

export const bankIDAuth = async (ssn?: string): Promise<BankIDAuth> => {
  const endUserIp: { ipAddress: string } = await getEndUserIp();
  return transport.post(AuthEndpoints.bankID + (ssn ? '/bankIdLoginFromOtherDevice' : '/bankIdLoginFromSameDevice'), {
    ipAddress: endUserIp.ipAddress,
  });
};

export const bankIDPoll = (orderRef: BankIDAuth['orderRef']): Promise<BankIDPoll> =>
  transport.post(`${AuthEndpoints.bankID}/authenticationForBankId`, { orderRef });
